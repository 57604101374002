(function prepareMobileSubMenus() {
    const subMenuIcons = [...document.querySelectorAll('.sub-menu-icon')];

    const hideAllSubMenus = () => {
        subMenuIcons.forEach(subMenuIcon => {
            const subMenuIconParentLink = subMenuIcon.parentElement;
            const subMenu = subMenuIconParentLink.nextElementSibling;
            subMenu.classList.remove('show');
            subMenuIcon.className = subMenuIcon.className.replace('up', 'down');
        })
    }

    const showSubMenu = (subMenuIcon, subMenu) => {
        const subMenuIconDisplayProperty = getComputedStyle(subMenuIcon).getPropertyValue('display').toLowerCase();

        if (subMenuIconDisplayProperty === 'none') {
            return;
        }
        
        if (subMenu.classList.contains('show')) {
            subMenu.classList.remove('show');
            subMenuIcon.className = subMenuIcon.className.replace('up', 'down');
        }
        else {
            hideAllSubMenus();
            subMenu.classList.add('show');
            subMenuIcon.className = subMenuIcon.className.replace('down', 'up');
        }
    };

    subMenuIcons.forEach(subMenuIcon => {
        const subMenuIconParentLink = subMenuIcon.parentElement;
        const subMenu = subMenuIconParentLink.nextElementSibling;
        subMenuIconParentLink.addEventListener('click', showSubMenu.bind(this, subMenuIcon, subMenu));
    });
})();